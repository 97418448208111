html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  letter-spacing: -0.1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0D013E;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--background-light, #F9F9F9);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 35.2px */
  letter-spacing: -0.1px;
}
